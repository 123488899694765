
import Vue, { PropType } from "vue";
import {
  VMenu,
  VBtn,
  VIcon,
  VList,
  VListItemGroup,
  VListItem,
  VListItemIcon,
  VListItemContent,
  VListItemTitle
} from "vuetify/lib";

export type MenuType = {
  text: string;
  to?: string;
  name?: string;
  icon?: string;
  hidden?: boolean;
};

export default Vue.extend({
  name: "MenuButton",
  components: {
    VMenu,
    VBtn,
    VIcon,
    VList,
    VListItemGroup,
    VListItem,
    VListItemIcon,
    VListItemContent,
    VListItemTitle
  },
  props: {
    menu: {
      type: Array as PropType<MenuType[]>,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  }
});
