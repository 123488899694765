
import MenuButton, { MenuType } from "@/components/MenuButton.vue";
import Vue from "vue";
import {
  VRow,
  VCol,
  VBtn,
  VIcon,
  VCard,
  VCardTitle,
  VCardText,
  VDivider
} from "vuetify/lib";

export type Menu = MenuType & {
  children?: MenuType[];
};

export default Vue.extend({
  name: "Office",
  components: {
    VRow,
    VCol,
    VBtn,
    VIcon,
    VCard,
    VCardTitle,
    VCardText,
    VDivider,
    MenuButton
  },
  computed: {
    isClient: {
      get(): boolean {
        return this.$store.state.authority === "1";
      }
    },
    order: {
      get(): Menu[] {
        return [
          { text: "受発注入力", to: "/list/jyutyuInput" },
          {
            text: "受発注フォーマット",
            to: "/jyutyuFormat",
            hidden: this.isClient
          },
          {
            text: "一括入力",
            children: [
              {
                text: "一括発注入力",
                to: "/order-batch-entry",
                icon: "mdi-notebook"
              },
              {
                text: "一括実数入力",
                to: "/received-order-batch-entry",
                hidden: this.isClient,
                icon: "mdi-notebook"
              }
            ]
          },
          {
            text: "受発注データ出力",
            to: "/jyutyuoutput",
            hidden: this.isClient
          },
          {
            text: "Excel出力",
            children: [
              { text: "受注一覧表", to: "/exceljyutyu", icon: "mdi-notebook" },
              {
                text: "ピッキングリスト",
                to: "/excelpicking",
                hidden: this.isClient,
                icon: "mdi-notebook"
              },
              { text: "納品書", to: "/excelnouhin", icon: "mdi-notebook" },
              {
                text: "発注数リスト",
                to: "/excelhattyu",
                hidden: this.isClient,
                icon: "mdi-notebook"
              }
            ]
          }
        ];
      }
    },
    master: {
      get(): Menu[] {
        return [
          { text: "ユーザマスタ", to: "/list/shain" },
          { text: "商品マスタ", to: "/list/shohin", hidden: this.isClient },
          { text: "単位マスタ", to: "/list/tanni", hidden: this.isClient },
          { text: "担当マスタ", to: "/list/tanto", hidden: this.isClient },
          {
            text: "得意先グループマスタ",
            to: "/list/tokuiGroup",
            hidden: this.isClient
          }
        ];
      }
    }
  },
  methods: {
    async routing(menu: MenuType) {
      //const dialog = new ShowDialogs();
      //if (!menu.to) dialog.$info("準備中です。", "情報");
      this.$router.push({ path: menu.to });
    }
  }
});
